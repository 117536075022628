<template>
  <div>
    <div class="page-banner">
      <div
        class="container"
        style="
          background-image: url('https://downloads.acs.com.hk/page-headers/2017-corporate-profile.jpg?5fc8a9b6a0943');
        "
      >
        <div class="wrapper">
          <div class="content">
            <h1>
              <a class="title">Mission & Core Values</a>
            </h1>
          </div>
        </div>
      </div>
    </div>
    <div class="container corporate-profile">
      <div class="row">
        <div class="col-md-10 offset-md-1">
          <div class="page-content">
            <div id="corporate-profile-content">
              <h5>Our Mission</h5>
              <p>
                At TBS, we focus on Business Solutions and assist customers to
                transform their business operation from one stage to another
                greater height.
              </p>

              <h5>Our Core Values</h5>
              <p>
                <b>Team Spirits</b>
                <br />
                All our team mates have only one common goal, which is to serve
                and provide the best business solutions to our customers.
              </p>
              <p>
                <b>System Development</b>
                <br />
                We focus on customers’ problems prior to design the system and
                we will deliver the most sensible and user-friendly solutions
                which exceed our customers’ expectations.
              </p>
              <p>
                <b>System Quality</b>
                <br />
                It is through our passion and commitment to “do it right at the
                first time” in order to deliver the best solutions to our
                clients. That’s build our systems’ qualities.
              </p>
              <p>
                <b>Self-growth</b>
                <br />
                To keep up to the latest technologies is our passion and
                commitment.
              </p>
              <p>
                <b>To Customers</b>
                <br />
                Continuously improving our customers’ productivities in both
                operational efficiency and market share growth are our primary
                solution’s directions
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MissionCoreValues"
};
</script>
